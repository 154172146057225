import { accMul, accAdd } from "./util"

export const getItemSubtotal = (items) => {
   let total = 0;
   items.forEach(item => {
      const { quantity, price } = item
      const currentItemTotal = accMul(quantity, price)
      total = accAdd(total, currentItemTotal)
   });
   return total
}