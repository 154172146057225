import React from 'react';
import { createRoot } from 'react-dom/client';
import { createQuote, placeOrder, Events, checkRaEligibility, TREET } from '../common'
import { gatherItemsInCheckout } from './util'
import configuration from './configuration.json'
import { Widget } from '../components/widget'
import { Banner } from '../components/banner'
import { QUOTE_COMPLETED, ELIGIBILITY_CHECK_COMPLETED, dispatchCustomEvent, FREEZE_TOGGLE } from '../common/events';
import {
  reportPdpWidgetLoadTime,
  reportCheckoutWidgetLoadTime,
  reportWidgetSwitchChageCartItems
} from "../eventTracking"

const SEEL_WIDGET_ROOT = 'seel-widget-element';
const SEEL_BANNER_ROOT = 'seel-banner-element';

const saveWidgetStatusToSession = (status) => {
  const { host } = window.location
  sessionStorage?.setItem(`${host}-seel-widget-status`, status);
}
const getWidgetStatusFromSession = () => {
  const { host } = window.location
  const status = sessionStorage?.getItem(`${host}-seel-widget-status`);
  return status === 'true'
}
let seelAssuranceChecked = false
document.addEventListener(Events.checked, (event) => {
  seelAssuranceChecked = true
  saveWidgetStatusToSession(seelAssuranceChecked)
  // reportWidgetSwitchChageCartItems(seelAssuranceChecked, event.detail)
})
document.addEventListener(Events.unchecked, (event) => {
  seelAssuranceChecked = false
  saveWidgetStatusToSession(seelAssuranceChecked)
  // reportWidgetSwitchChageCartItems(seelAssuranceChecked, event.detail)
})

const triggerEvent = (eventName, data = {}) => {
  const event = new CustomEvent(eventName, {
    detail: { data }
  });
  document.dispatchEvent(event);
}

const setupListener = (eventName, handler = () => { }) => {
  if (eventName === Events.checked || eventName === Events.unchecked) {
    document.addEventListener(eventName, (event) => {
      handler(event?.detail || {})
    })
  }
}

window.SeelSDK = {
  Events,
  seelSDK: {
    checkRaEligibility,
    createQuote: createQuote(TREET),
    placeOrder: async (order) => {
      return await placeOrder(order, seelAssuranceChecked)
    },
    triggerEvent,
    setupListener,
    freezeToggle: (value) => {
      dispatchCustomEvent(FREEZE_TOGGLE, { status: 'success', data: value })
    }
  }
}

let observer = null

let mutationCallback = ({ status, price, quoteId, extraInfo, config, total, wgStartLoadTime }) => (_, observer) => {

  const anchorEl = document.querySelector(config.anchor)
  if (anchorEl) {
    observer.disconnect()
    observer = null
    const seelWidgetRoot = document.createElement('div');
    seelWidgetRoot.setAttribute('id', SEEL_WIDGET_ROOT);
    anchorEl.append(seelWidgetRoot);
    const root = createRoot(seelWidgetRoot)

    root.render(<Widget key='async' {...{ status, price, quoteId, extraInfo, config, total }} />)
    reportCheckoutWidgetLoadTime({ wgStartLoadTime, price, quoteId, total });
  }
}
const asyncInsert = ({ status, price, quoteId, extraInfo, config, total, wgStartLoadTime }) => {

  if (observer) {
    observer.disconnect()
    observer = null
  }
  const targetNode = document.querySelector("body");
  const observerOptions = {
    childList: true,
    attributes: true,
    subtree: true,
  };

  observer = new MutationObserver(mutationCallback({ status, price, quoteId, extraInfo, config, total, wgStartLoadTime }));
  observer.observe(targetNode, observerOptions);
}
const insertWidget = async ({ status, price, quoteId, extraInfo, config, total }) => {
  //checkout widget加载开始时间
  const wgStartLoadTime = Date.now()

  if (document.querySelector(`#${SEEL_WIDGET_ROOT}`)) {
    return
  }

  const { anchor } = config
  const anchorEl = document.querySelector(anchor);

  if (!anchorEl) {
    asyncInsert({ status, price, quoteId, extraInfo, config, total, wgStartLoadTime })

  } else {
    const seelWidgetRoot = document.createElement('div');
    seelWidgetRoot.setAttribute('id', SEEL_WIDGET_ROOT);
    anchorEl.append(seelWidgetRoot);
    const root = createRoot(seelWidgetRoot)

    root.render(<Widget key='sync' {...{ status, price, quoteId, extraInfo, config, total }} />)
    reportCheckoutWidgetLoadTime({ wgStartLoadTime, price, quoteId, total });

  }
}

const insertBanner = async ({ config }) => {
  const { anchor } = config
  const anchorEl = document.querySelector(anchor);
  if (!anchorEl) {
    return null
  }
  const seelBannerRoot = document.createElement('div');
  seelBannerRoot.setAttribute('id', SEEL_BANNER_ROOT);
  anchorEl.append(seelBannerRoot);
  const root = createRoot(seelBannerRoot)
  root.render(<Banner {...{ config }} />)
  reportPdpWidgetLoadTime()
}

const initialize = async () => {
  document.addEventListener(QUOTE_COMPLETED, async (event) => {
    const { pathname } = window.location
    const { status, data, reason, message } = event.detail

    console.log('QUOTE EVENT', event.detail)
    // if (status !== 'success') {
    //   console.log(message || reason)
    //   return;
    // }

    if (pathname === '/checkout') {
      const { price, quoteId, extraInfo, total } = data
      let config = configuration.embeds.find(_ => _.layout == 'checkout')
      const { meta = {}, ...rest } = config || {}
      config = {
        ...rest,
        meta: {
          ...meta,
          check: getWidgetStatusFromSession()
        }
      }

      await insertWidget({ status, price, quoteId, extraInfo, config, total })

    }
  })

  // document.addEventListener(ELIGIBILITY_CHECK_COMPLETED, async (event) => {
  //   if (document.querySelector(`#${SEEL_BANNER_ROOT}`)) {
  //     return
  //   }
  //   const { status, data = {}, reason, message } = event.detail
  //   const { eligibility } = data
  //   if(status != 'success' && eligibility != true){
  //     return
  //   }
  //   const config = configuration.embeds.find(_ => _.layout == 'product')
  //   await insertBanner({ config })
  // })
  // checkRaEligibility()

  const callback = async (mutationList, observer) => {
    if (document.querySelector(`#seel-ra-marketing-banner-root`) && !document.querySelector(`#${SEEL_BANNER_ROOT}`)) {
      const config = configuration.embeds.find(_ => _.layout == 'product')
      await insertBanner({ config })
    }
  };

  // Create an observer instance linked to the callback function
  const observer = new MutationObserver(callback);
  // Start observing the target node for configured mutations
  observer.observe(document.querySelector('body'), { attributes: true, childList: true, subtree: true });
}

try {
  console.log("current version 1.2.7")
  initialize()
} catch (err) {

}
