import React from 'react';
import Modal from 'react-modal';

import './widget.css';

const customStyles = {
    overlay: {
        zIndex: 999,
        backgroundColor: 'rgb(24 32 38 / 60%)'
    },
    content: window?.innerWidth > 700 ? {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '760px',
        padding: '30px',
        borderRadius: '20px',
    } : {
        top: '40px',
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        height: `${window?.innerHeight - 40}px`,
        minWidth: '240px',
        borderRadius: '20px',
        padding: '30px',
        paddingTop: '80px',
        paddingBottom: getComputedStyle(document.documentElement).getPropertyValue("--sat")
    },
};

export const TreetModal = ({ open, config, handleShowModal }) => {

    const { observers, assets, meta } = config;
    const { live, check } = meta || {}
    const { policy, name, icon } = assets;
    const { contents = "", links = [], title = '', note } = policy || {}
    return <Modal
        isOpen={open}
        ariaHideApp={false}
        onRequestClose={() => {
            handleShowModal(false);
        }}
        style={customStyles}
    >
        <div className='seel--widget_modal--header' style={window?.innerWidth > 700 ? {
            position: "relative", top: 0, left: 0
        } : {}}>
            <div className='seel--widget_modal--title'>
                <div>Return For Any Reason</div>
                <div style={{ fontSize: "14px" }}>Just Pay Shipping</div>
            </div>
            <div style={{ height: '60px', backgroundColor: 'white', width: '18px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }} onClick={() => {
                handleShowModal(false);
            }}>
                <img src="https://cdn.seel.com/assets/images/close_icon.svg" alt="Click for close" className='seel--widget_modal--close' />
            </div>
        </div>
        <div className='seel--widget_modal--blocks' style={window?.innerWidth > 700 ? {} : {
            display: 'flex',
            flexDirection: 'column'
        }}>
            <div className='seel--widget_modal--blocks-section' style={window?.innerWidth > 700 ? {} : {
                width: '100%',
                marginBottom: '15px'
            }}>
                <div className='seel--widget_modal--blocks-section-header'>Return for any reason</div>
                <div className='seel--widget_modal--blocks-section-content' style={{ alignItem: 'flex-start' }}>
                    <div>
                        {
                            ['Item doesn’t fit', 'No longer needed', 'Dissatisfied with items', 'Arrived too late'].map((text, idx) => {
                                return <div key={`${idx}-a`} className='seel--widget_modal--blocks-section-content-text' style={{ width: '100%' }}>
                                    {/* <img className="seel--widget_modal--blocks-section-content-text-icon" src="https://cdn.seel.com/assets/images/treet/radio-checked.svg" /> */}
                                    <svg className="seel--widget_modal--blocks-section-content-text-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8ZM12.16 6.56C12.2755 6.40594 12.3316 6.21538 12.318 6.02329C12.3043 5.8312 12.2219 5.65048 12.0857 5.51431C11.9495 5.37815 11.7688 5.29566 11.5767 5.28201C11.3846 5.26836 11.1941 5.32446 11.04 5.44L7.2 9.28L5.36 7.44C5.20594 7.32446 5.01538 7.26836 4.82329 7.28201C4.6312 7.29566 4.45048 7.37815 4.31431 7.51431C4.17815 7.65048 4.09566 7.8312 4.08201 8.02329C4.06836 8.21538 4.12446 8.40594 4.24 8.56L6.64 10.96C6.96 11.28 7.44 11.28 7.76 10.96L12.16 6.56Z" fill="#5B5B5B" />
                                    </svg>
                                    {text}</div>
                            })
                        }
                    </div>
                </div>
            </div>
            <div className='seel--widget_modal--blocks-section' style={window?.innerWidth > 700 ? {} : {
                width: '100%',
                marginBottom: '15px'
            }}>
                <div className='seel--widget_modal--blocks-section-header'>Extended return window</div>
                <div className='seel--widget_modal--blocks-section-content' style={{ justifyContent: 'flex-start' }}>
                    <div style={{ height: '30px', width: '30px' }} />
                    {/* <img className='seel--widget_modal--blocks-section-content-img' src="https://cdn.seel.com/assets/images/treet/widget-icon.svg" /> */}
                    <svg className='seel--widget_modal--blocks-section-content-img' width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M39.6491 16.317C38.8159 16.681 37.8957 16.883 36.9283 16.883C33.1718 16.883 30.1264 13.8377 30.1264 10.0811C30.1264 8.89388 30.4306 7.77769 30.9653 6.80621C25.1243 5.61022 19.072 5.79993 13.2981 7.37533L11.0092 7.99985C8.64206 8.64572 7 10.7958 7 13.2495V20.4166C7 26.9433 9.9288 33.1256 14.9793 37.2596L19.8779 41.2693C21.8827 42.9104 24.7664 42.9104 26.7712 41.2693L31.6698 37.2596C36.7203 33.1256 39.6491 26.9433 39.6491 20.4166V16.317ZM23.3246 23.6849H36.6459C35.8678 28.1524 33.5111 32.2364 29.9465 35.1541L25.0479 39.164C24.5467 39.5742 23.9356 39.7793 23.3246 39.7793V23.6849ZM23.3246 8.75278V23.6849H10.0031C9.81658 22.6139 9.72076 21.5208 9.72076 20.4166V13.2495C9.72076 12.0227 10.5418 10.9476 11.7253 10.6247L14.0142 10.0001C17.0619 9.16857 20.1932 8.75278 23.3246 8.75278Z" fill="#5B5B5B" />
                        <path d="M38.2886 7.36038C38.2886 6.60906 37.6796 6 36.9283 6C36.1769 6 35.5679 6.60906 35.5679 7.36038V12.8019C35.5679 13.5532 36.1769 14.1623 36.9283 14.1623C37.6796 14.1623 38.2886 13.5532 38.2886 12.8019V7.36038Z" fill="#5B5B5B" />
                        <path d="M39.6489 11.4415C40.4002 11.4415 41.0093 10.8324 41.0093 10.0811C41.0093 9.32981 40.4002 8.72075 39.6489 8.72075H34.2074C33.4561 8.72075 32.847 9.32981 32.847 10.0811C32.847 10.8324 33.4561 11.4415 34.2074 11.4415H39.6489Z" fill="#5B5B5B" />
                    </svg>
                    <div className='seel--widget_modal--blocks-section-content-b'>
                        <div style={{ textAlign: 'center', lineHeight: '18px', fontSize: '14px', color: '#565656' }}>7-day return window</div>
                        {/* <img src="https://cdn.seel.com/assets/images/treet/modal-switch-new.svg" style={{ width: '40px', display: 'flex', marginLeft: '8px' }} /> */}
                    </div>
                </div>
            </div>
            <div className='seel--widget_modal--blocks-section' style={window?.innerWidth > 700 ? {} : {
                width: '100%',
                marginBottom: '15px'
            }}>
                <div className='seel--widget_modal--blocks-section-header'>Instant resolution</div>
                <div className='seel--widget_modal--blocks-section-content' style={{ justifyContent: 'flex-start' }}>
                    <div style={{ height: '30px', width: '30px' }} />
                    <svg className='seel--widget_modal--blocks-section-content-img' width="30" height="46" viewBox="0 0 30 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M18.0002 5H19.5002V0.319305L16.7797 4.12814L18.0002 5ZM18.0002 20H16.5002V21.5H18.0002V20ZM3.00023 26L1.77964 25.1282L0.0854492 27.5H3.00023V26ZM12.0002 26H13.5002V24.5H12.0002V26ZM12.0002 41H10.5002V45.6808L13.2208 41.8718L12.0002 41ZM27.0002 20L28.2208 20.8718L29.915 18.5H27.0002V20ZM16.5002 5V20H19.5002V5H16.5002ZM4.22083 26.8718L19.2208 5.87186L16.7797 4.12814L1.77964 25.1282L4.22083 26.8718ZM12.0002 24.5H3.00023V27.5H12.0002V24.5ZM13.5002 41V26H10.5002V41H13.5002ZM25.7797 19.1282L10.7797 40.1282L13.2208 41.8718L28.2208 20.8718L25.7797 19.1282ZM18.0002 21.5H27.0002V18.5H18.0002V21.5Z" fill="#5B5B5B" />
                    </svg>

                    {/* <img className='seel--widget_modal--blocks-section-content-img' src="https://cdn.seel.com/assets/images/treet/lightning.svg" /> */}
                    <div style={{ textAlign: 'center', lineHeight: '18px', fontSize: '14px', color: '#565656', padding: "0 10px" }}>Instantly resolve your return request and get refunded with a few clicks.</div>
                </div>
            </div>
        </div>
        <div className='seel--widget_modal--paragraph' dangerouslySetInnerHTML={{ __html: contents }}></div>
        <div className='seel--widget_modal--paragraph seel--widget_modal--bold'>{note}</div>
        <div className='seel--widget_modal--paragraph'>{'* Your order may be ineligible for Return Assurance if the order value exceeds our price cap or you are shipping to an address outside of the United States.'}</div>
    </Modal>
}
