import React, { useEffect, useRef } from 'react';
import template from 'lodash.template';
import Switch from "react-switch";
import Skeleton from 'react-loading-skeleton'
// import Modal from 'react-modal';
import { TreetModal } from './treetModal'

import './widget.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { Events, dispatchCustomEvent, QUOTE_SENDED, QUOTE_COMPLETED, FREEZE_TOGGLE } from '../common/events';
import { reportWidgetInfoIconClick, reportWidgetSwitchChageCartItems } from "../eventTracking"

const getWidgetStatusFromSession = () => {
  const { host } = window.location
  const status = sessionStorage?.getItem(`${host}-seel-widget-status`);
  return status === 'true'
}

export const Widget = ({ status: quoteStatus, price: quotePrice, quoteId, extraInfo, config, total }) => {

  const { observers, assets, meta } = config;
  const { live, check } = meta || {}
  const { policy, name, icon } = assets;
  const { contents = "", links = [], title = '', note } = policy || {}
  const [status, setStatus] = React.useState(quoteStatus)
  const [modalIsOpen, setModalIsOpen] = React.useState(false)
  const [checked, setChecked] = React.useState(getWidgetStatusFromSession() !== null ? getWidgetStatusFromSession() : check)
  const [price, setPrice] = React.useState(quotePrice)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isError, setIsError] = React.useState(false)
  const quoteRef = useRef({ quoteId, extraInfo, total })
  const sended = useRef(false)
  const [freezeToggle, setFreezeToggle] = React.useState(false)

  const subLine = isLoading ? template(assets.template)({ price: '' }) : template(assets.template)({ price: price == null ? '' : `$${price}` })

  React.useEffect(() => {
    if (!sended.current && checked) {
      dispatchCustomEvent(checked ? Events.checked : Events.unchecked, { price, quoteId: quoteRef.current.quoteId, extraInfo: quoteRef.current.extraInfo, checked, total: quoteRef.current.total })
      sended.current = true
    }
  }, [checked, price])

  React.useEffect(() => {
    document.addEventListener(QUOTE_SENDED, (event) => {
      setIsLoading(true)
    })
    document.addEventListener(QUOTE_COMPLETED, (event) => {
      const { status, data, reason, message } = event.detail

      console.log("event.detail", event.detail)
      setStatus(status)
      if (status === 'success') {

        const {
          price: _price,
          quoteId: _quoteId,
          extraInfo: _extraInfo,
          total: _total
        } = data


        setPrice(_price)
        quoteRef.current.extraInfo = _extraInfo
        quoteRef.current.quoteId = _quoteId
        quoteRef.current.total = _total
      }
      setIsLoading(false)
    })
    document.addEventListener(Events.checked, (event) => {
      setChecked(true)
    })
    document.addEventListener(Events.unchecked, (event) => {
      setChecked(false)
    })
    document.addEventListener(FREEZE_TOGGLE, ({ detail }) => {
      setFreezeToggle(detail.data)
    })
    // Banner 点击弹窗
    // document.addEventListener('OPEN_WIDGET_MODAL', ({ detail }) => {
    //   setModalIsOpen(detail.data)
    // })
  }, [])

  return <div className="seel--widget_container" style={status !== 'success' ? { minHeight: '80px', backgroundColor: '#DFE4E8', alignItems: 'center' } : {}}>
    {/* <img className="seel--widget_image" src={icon} alt="" /> */}
    <svg className="seel--widget_image" width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M39.6491 16.317C38.8159 16.681 37.8957 16.883 36.9283 16.883C33.1718 16.883 30.1264 13.8377 30.1264 10.0811C30.1264 8.89388 30.4306 7.77769 30.9653 6.80621C25.1243 5.61022 19.072 5.79993 13.2981 7.37533L11.0092 7.99985C8.64206 8.64572 7 10.7958 7 13.2495V20.4166C7 26.9433 9.9288 33.1256 14.9793 37.2596L19.8779 41.2693C21.8827 42.9104 24.7664 42.9104 26.7712 41.2693L31.6698 37.2596C36.7203 33.1256 39.6491 26.9433 39.6491 20.4166V16.317ZM23.3246 23.6849H36.6459C35.8678 28.1524 33.5111 32.2364 29.9465 35.1541L25.0479 39.164C24.5467 39.5742 23.9356 39.7793 23.3246 39.7793V23.6849ZM23.3246 8.75278V23.6849H10.0031C9.81658 22.6139 9.72076 21.5208 9.72076 20.4166V13.2495C9.72076 12.0227 10.5418 10.9476 11.7253 10.6247L14.0142 10.0001C17.0619 9.16857 20.1932 8.75278 23.3246 8.75278Z" fill="#5B5B5B" />
      <path d="M38.2886 7.36038C38.2886 6.60906 37.6796 6 36.9283 6C36.1769 6 35.5679 6.60906 35.5679 7.36038V12.8019C35.5679 13.5532 36.1769 14.1623 36.9283 14.1623C37.6796 14.1623 38.2886 13.5532 38.2886 12.8019V7.36038Z" fill="#5B5B5B" />
      <path d="M39.6489 11.4415C40.4002 11.4415 41.0093 10.8324 41.0093 10.0811C41.0093 9.32981 40.4002 8.72075 39.6489 8.72075H34.2074C33.4561 8.72075 32.847 9.32981 32.847 10.0811C32.847 10.8324 33.4561 11.4415 34.2074 11.4415H39.6489Z" fill="#5B5B5B" />
    </svg>
    <div className="seel--widget_mid">
      <div className="seel--widget_content">
        <div className="seel--widget_content--primary" onClick={() => {
          setModalIsOpen(true)
          reportWidgetInfoIconClick()
        }}>
          <div className="seel--widget_content--primary--text">{name}</div>
          {policy ?
            <img className="seel--widget_content--primary--icon" src={"https://cdn.seel.com/assets/images/buyer-protection/info_icon.svg"} alt="Click for details" />
            : null}
        </div>
        <div className="seel--widget_content--secondary">
          {
            status !== 'success' ? <div className="seel--widget_content--secondary--error">{"Return Assurance isn't eligible for this order. This may be due to your order being outside of the U.S., your order value being above $1,000.00, or other ineligibility criteria."}</div> :
              <>
                <div className="seel--widget_content--secondary--text" dangerouslySetInnerHTML={{ __html: subLine }} />
                {isLoading && <Skeleton containerClassName="seel--widget_content--secondary--skeleton_wrap" className="seel--widget_content--secondary--skeleton" />}
              </>
          }
        </div>
      </div>
      {
        status !== 'success' ? null : <>
          <div style={window?.innerWidth <= 375 ? { width: '8px', height: '8px' } : { width: '20px', height: '20px' }} />
          <div className="seel--widget_mid-list">
            {
              ['Item doesn’t fit', 'No longer needed', 'Dissatisfied with items', 'Arrived too late'].map((text, idx) => {
                return <div key={`${idx}-a`} className='seel--widget_mid-list-item'>
                  {/* <img style={{ width: '16px', marginRight: '8px' }} src="https://cdn.seel.com/assets/images/treet/radio-checked.svg" /> */}
                  <svg style={{ width: '16px', marginRight: '8px' }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8ZM12.16 6.56C12.2755 6.40594 12.3316 6.21538 12.318 6.02329C12.3043 5.8312 12.2219 5.65048 12.0857 5.51431C11.9495 5.37815 11.7688 5.29566 11.5767 5.28201C11.3846 5.26836 11.1941 5.32446 11.04 5.44L7.2 9.28L5.36 7.44C5.20594 7.32446 5.01538 7.26836 4.82329 7.28201C4.6312 7.29566 4.45048 7.37815 4.31431 7.51431C4.17815 7.65048 4.09566 7.8312 4.08201 8.02329C4.06836 8.21538 4.12446 8.40594 4.24 8.56L6.64 10.96C6.96 11.28 7.44 11.28 7.76 10.96L12.16 6.56Z" fill="#5B5B5B" />
                  </svg>
                  {text}</div>
              })
            }
          </div>
        </>
      }
    </div>
    <div className="seel--widget_end">
      {

        status !== 'success' ? null : <Switch

          onChange={() => {
            const nextStatus = !checked
            dispatchCustomEvent(nextStatus ? Events.checked : Events.unchecked, { price, quoteId: quoteRef.current.quoteId, extraInfo: quoteRef.current.extraInfo, checked: nextStatus, total: quoteRef.current.total })
            setChecked(nextStatus)
            reportWidgetSwitchChageCartItems(nextStatus,{ price, quoteId: quoteRef.current.quoteId, total: quoteRef.current.total })
          }}
          checked={checked || false}
          className="seel--widget_button"
          disabled={isLoading || freezeToggle}
          uncheckedIcon={<div />}
          checkedIcon={false}
          height={window?.innerWidth <= 375 ? 16 : 24}
          width={window?.innerWidth <= 375 ? 32 : 48}
          handleDiameter={window?.innerWidth <= 375 ? 12 : 20}
          offColor="#BDBDBD"
          onColor="#0A0A0A"
          activeBoxShadow="0px 0px 1px 2px #9B95FF"
          aria-label="Click to toggle between on and off"
        />
      }
    </div>
    <div className="seel--widget_end-copywright">
      POWERED BY
      <img className="seel--widget_end-copywright-logo" alt="seel logo" src="https://cdn.seel.com/assets/images/treet/grey-letter-logo.svg" />
    </div>
    <TreetModal open={modalIsOpen} config={config} handleShowModal={(val) => setModalIsOpen(val)} />
  </div>
}
