import { QUOTE_COMPLETED, QUOTE_SENDED, ELIGIBILITY_CHECK_COMPLETED, dispatchCustomEvent } from "./events"
import { TREET, GENTLY } from "./constant"
import { getItemSubtotal } from "../eventTracking/calc"
import { reportCreateQuoteTriggered } from "../eventTracking"

const quoteArr = []
const mockTitle = ['mock_success', 'mock_success_return_quote_reject', 'mock_bad_request', 'mock_internal_server_error']

const formatQuotePayload = (quote) => {
  const { items = [], shipping_address, extra_info = {} } = quote

  let item = items[0] || {}
  const { title } = item
  if (mockTitle.indexOf(title) == -1) {
    item.title = mockTitle[0]
  }

  const cart = [item]

  return {
    "quote_basic_info": {
      "customer_info": {
        "applicant": {
          "identity": "example1@seel.com"
        },
        "insured": {
          "identity": "example2@seel.com"
        }
      },
      "time_info": {
        "created_ts": 1675913847000,
        "expired_ts": 1676013847000
      },
      "currency": "USD",
      "effective_period": 5
    },
    "quote_subject_matters": {
      "unchecked_items": {
        cart, shipping_address, extra_info
      }
    },
    "quote_plan": {
      "code": "",
      "coverage": [
        "",
        "",
        ""
      ],
      "special_agreement": "",
      "liability_limit": {
        "deductible": 0,
        "deductible_ratio": 0,
        "max_indemnity": 100
      }
    }
  }
}

const formatPolicyPayload = (quote, order) => {
  const { cart, shipping_address, extra_info = {} } = quote.input?.quote_subject_matters?.unchecked_items || {}
  const { quoteId: quote_id } = quote.output
  const { sales_tax_rate: tax, customer, order_id } = order
  const { email: identity, ...rest } = customer
  return {
    "policy_basic_info": {
      quote_id,
      "customer_info": {
        "applicant": {
          "identity": "example1@seel.com"
        },
        "insured": {
          identity,
          ...rest
        }
      },
      "time_info": {
        "created_ts": 1675913847000,
        "expired_ts": 1676013847000
      },
      "currency": "USD"
    },
    "policy_subject_matters": {
      order_id,
      "unchecked_items": { cart, shipping_address, extra_info }
    },
    "policy_plan": {
      "code": "",
      "coverage": [
        "",
        "",
        ""
      ],
      "special_agreement": "",
      "liability_limit": {
        "deductible": 0,
        "deductible_ratio": 0,
        "max_indemnity": 100
      }
    },
    "policy_cost_info": {
      tax,
      "transaction_fee": 0.8
    }
  }
}

export const checkRaEligibility = async () => {
  const result = true
  dispatchCustomEvent(ELIGIBILITY_CHECK_COMPLETED, {status: 'success', data: {eligibility: result}})
  return result
}

export const createQuote = (namespace) => async (payload) => {
  const start_time = Date.now()
  let isTreet  = false
  try {
    dispatchCustomEvent(QUOTE_SENDED, {status: 'success', data: payload})
    // const body = formatQuotePayload(payload)
    let path = ''
    let apiKey = ''
    if(namespace === GENTLY){
      path = 'gently-ra';
      apiKey = process.env.API_KEY_4_GENTLY
    }else if(namespace === TREET){
      path = 'treet-ra'
      apiKey = process.env.API_KEY_4_TREET
      isTreet = true
    }else{
    }
    const options = {
      method: 'POST',
      headers: {
        "X-Seel-Api-Key": apiKey,
        "X-Seel-Api-Version": process.env.API_VERSION,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload),
    };

    const response = await fetch(`https://${process.env.SERVICE_DOMAIN}/${process.env.SERVICE_VERSION}/${path}/quotes`, options)
    
    if(!response?.ok){
      return { status: 'rejected' }
    }
    
    const responseText = await response.text()
    const data = JSON.parse(responseText)
    const { quote_id: quoteId, price, extra_info, status } = data || {}

    //获取checkout中items的总价
    const total = getItemSubtotal(payload.items)

    const result = {
      quoteId,
      price,
      extraInfo: extra_info || {},
      status,
      total
    }
    // result.status = Number(result.price) ? "accepted" : "rejected"
    
    // console.log('create quote response', result)
    
    if (result?.price) {
      quoteArr.push({
        input: payload,
        output: result
      })
      console.log('stashed quote', quoteArr)
      dispatchCustomEvent(QUOTE_COMPLETED, { status: 'success', data: result })
      reportCreateQuoteTriggered({isTreet,start_time,quoteId,quote_result:"success"})
    }else{
      dispatchCustomEvent(QUOTE_COMPLETED, { status: 'fail', data: result ,reason: 'invalid price' })
      reportCreateQuoteTriggered({isTreet,start_time,quoteId,quote_result:"fail"})
    }

    return result
  } catch (e) {
    dispatchCustomEvent(QUOTE_COMPLETED, { status: 'error', message: e.message || '' })
    reportCreateQuoteTriggered({isTreet,start_time,quoteId:"",quote_result:"error"})
    console.log(e)
    return { status: 'rejected' }
  }
}

export const placeOrder = async (order, checkStatus) => {
  try {
    console.log('place order request', order)
    if (checkStatus) {
      const quote = quoteArr.pop()
      if(quote && quote.input && quote.output){
        return await createPolicy(quote, order)
      }else{
        return {message: 'order data received'}
      }
    }else {
      return {message: 'order data received'}
    }
  } catch (e) {
    console.log(e)
  }
}

export const createPolicy = async (quote, order) => {
  try {
    return {message: 'policy created', policyId: '202208030172794092175970'}
    // const body = formatPolicyPayload(quote, order)
    // console.log('create policy request', body)
    // const options = {
    //   method: 'POST',
    //   headers: {
    //     "API-Key": "4A65EC4F911642068881F4C08761BDD7",
    //     "Content-Type": "application/json"
    //   },
    //   body: JSON.stringify(body),
    // };

    // const response = await fetch(`https://${process.env.SERVICE_DOMAIN}/v1-1/policies`, options)
    // const responseText = await response.text()
    // const data = JSON.parse(responseText)
    // console.log('create policy response', data)
    // const { policyId, message, createSuccess } = data 
    // if(String(createSuccess) == 'true' && policyId){
    //   return {message: 'policy created', policyId}
    // }else{
    //   return {message}
    // }
  } catch (e) {
    console.log(e)
  }
}
