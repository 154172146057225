import { sendReportEventRequest } from "./reportEvent"

//pdp widget加载时间事件上报
export const reportPdpWidgetLoadTime = () => {
    const info = {
        url: window.location.pathname
    }
    sendReportEventRequest("pdp_banner_show", info, "treet")
}
//checkout widget加载时间事件上报
export const reportCheckoutWidgetLoadTime = (params) => {
    const widget_show_ts = Date.now()
    const {
        wgStartLoadTime: start_loading_ts,
        price: quote_price,
        quoteId: quote_id,
        total: item_subtotal
    } = params
    const info = {
        start_loading_ts,
        widget_show_ts,
        quote_id,
        quote_price,
        item_subtotal
    }
    sendReportEventRequest("widget_show", info, "treet")
}
//checkout页面widget info icon点击上报
export const reportWidgetInfoIconClick = () => {
    sendReportEventRequest("widget_info_icon_clicked", {}, "treet")
}
//checkout页面widget switch状态更好事件上报
export const reportWidgetSwitchChageCartItems = (status, params) => {
    const {
        price: quote_price,
        quoteId: quote_id,
        total: item_subtotal
    } = params

    const info = {
        quote_id,
        quote_price,
        item_subtotal
    }
    const eventName = status ? 'widget_toggled_on' : 'widget_toggled_off';
    sendReportEventRequest(eventName, info, "treet")
}

//checkout页面Quote事件上报
export const reportCreateQuoteTriggered = (params) => {
    const {
        isTreet,
        start_time,
        quoteId,
        quote_result
    } = params
    if (!isTreet) return
    const quote_response_time = Date.now() - start_time
    const info = {
        quote_id: quoteId,
        quote_result,
        quote_response_time,
        shop_url:window.location?.origin
    }
    const eventName = 'create_quote_triggered';
    sendReportEventRequest(eventName, info, "treet")
}

/**
 * treet customer behavior
 */

const getCommonInfo = (productId, productPrice) => {
    const url = window.location.pathname
    // const productId = url.split("/")[3]
    const res = {
        productId,//商品id
        productPrice,//商品价格
        productSKUId: "",//商品sku id
        url,//page url
        ip: "",//ip 地址
        deviceId: "",//设备id
        userAgent: "",//ua
        macAaddress: "",//mac 地址
    }
    return res
}


const pdpPageCommonFC = (domTag, eventName) => {
    window.onload = () => {
        const pathname = window.location.pathname
        const pathnameArr = pathname.split("/")
        const currentPage = pathnameArr[1]
        const productId = pathnameArr[3]
        const pdpAddToBagBtnClick = () => {
            const productPrice = document.
                querySelector(".ListingPage_desktopPriceValue__2FLH3")
                .getAttribute("title").split("Listed Price: ")[1]
            const info = getCommonInfo(productId, productPrice)
            sendReportEventRequest(eventName, info, "treet")
        }
        const element = document.querySelector(domTag)
        if (currentPage === "l") {
            element.addEventListener("click", pdpAddToBagBtnClick)
        } else {
            element.removeEventListener("click", pdpAddToBagBtnClick)
        }
    }
}

//pdp页面添加至购物车事件
export const pdpAddToBag = (dom = ".ListingPage_buttonsWrapper__3FByu .ListingPage_addToBagButton__11-1c") => {
    pdpPageCommonFC(dom, "add_to_bag")
}

//pdp页面商品收藏事件
export const pdpProductCollect = (dom = ".ListingPage_buttonsWrapper__3FByu .FavoriteButton_root__NncXT") => {
    pdpPageCommonFC(dom, "collect_product")
}

//product list 页面商品收藏事件

export const productListCollect = (dom = ".FavoriteButton_root__NncXT") => {
    document.querySelectorAll(dom).forEach(item => {
        item?.addEventListener("click", () => {
            const info = getCommonInfo()
            sendReportEventRequest("product_list_collect_product", info, "treet")
        })
    })
}


//搜索筛选事件
export const productFilter = (dom) => {
    document.querySelector(dom)?.addEventListener("click", () => {
        const info = getCommonInfo()
        sendReportEventRequest("product_filter", info, "treet")
    })
}

// 购物车删除商品-清空购物车
export const deleteAllProduct = (dom = ".ShoppingBag_content__1Q8Kd .Button_root__2bb-J") => {
    document.querySelector(dom).addEventListener("click", () => {
        const info = getCommonInfo()
        sendReportEventRequest("all_product_delete", info, "treet")
    })
}

// 购物车删除商品-单个商品删除
export const deleteCurrentProduct = (dom = ".ListingCardPreview_removeListingButton__2ei3C") => {
    document.querySelectorAll(dom).forEach(item => {
        item.addEventListener("click", () => {
            const info = getCommonInfo()
            sendReportEventRequest("single_product_delete", info, "treet")
        })
    })

}