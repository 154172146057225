//除法

const accDiv = (a, b) => {
    let c = 0;
    let d = 0;
    let e = 0;
    let f = 0;
    try {
        e = a.toString().split(".")[1].length;
    } catch (g) { }
    try {
        f = b.toString().split(".")[1].length;
    } catch (g) { }
    return (
        (c = Number(a.toString().replace(".", ""))),
        (d = Number(b.toString().replace(".", ""))),
        accMul(c / d, Math.pow(10, f - e))
    );
};

//乘法

const accMul = (a, b) => {
    let c = 0;
    let d = a.toString();
    let e = b.toString();
    try {
        c += d.split(".")[1].length;
    } catch (f) { }
    try {
        c += e.split(".")[1].length;
    } catch (f) { }
    return (
        (Number(d.replace(".", "")) * Number(e.replace(".", ""))) / Math.pow(10, c)
    );
};

//加法

const accAdd = (a, b) => {
    let c;
    let d;
    let e;
    if (undefined == a || null == a || "" == a || isNaN(a)) {
        a = 0;
    }
    if (undefined == b || null == b || "" == b || isNaN(b)) {
        b = 0;
    }
    try {
        c = a.toString().split(".")[1].length;
    } catch (f) {
        c = 0;
    }
    try {
        d = b.toString().split(".")[1].length;
    } catch (f) {
        d = 0;
    }
    e = Math.pow(10, Math.max(c, d));
    return (accMul(a, e) + accMul(b, e)) / e;
};

//减法

const Subtr = (a, b) => {
    let c;
    let d;
    let e;
    if (undefined == a || null == a || "" == a || isNaN(a)) {
        a = 0;
    }
    if (undefined == b || null == b || "" == b || isNaN(b)) {
        b = 0;
    }
    try {
        c = a.toString().split(".")[1].length;
    } catch (f) {
        c = 0;
    }
    try {
        d = b.toString().split(".")[1].length;
    } catch (f) {
        d = 0;
    }
    e = Math.pow(10, Math.max(c, d));
    return (accMul(a, e) - accMul(b, e)) / e;
};

export { accDiv, accMul, accAdd, Subtr };
